import moment from 'moment';
import 'moment/locale/de';
import { Note } from './note.model';
import { Topic } from './topic.model';
import { Message } from './message.model';
import { ExportOperation } from './export-operation.model';
import { Operation as ImportOperation } from '@app/models/import/operation.model';
import { User } from './user.model';
import { Contract } from './contract.model';
import { PublicItem } from './public-item.model';
import { Damage } from './damage.model';

export class Notification {
  id: number;
  state: string;
  notificable_type: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  notificable: Note | Topic | Message | ExportOperation | User | Contract | Damage | ImportOperation | PublicItem;
  notificable_record: any;
  reminder: boolean;
  user_id: number;
  stream_cid: string;

  get createdAt() {
    return this.created_at ? moment(this.created_at) : null;
  }

  fromJson(source: any): Notification {
    Object.assign(this, source);
    if (source.notificable_type === 'Note') {
      this.notificable = new Note().fromJson(source.notificable);
    } else if (source.notificable_type === 'Topic') {
      this.notificable = new Topic().fromJson(source.notificable);
    } else if (source.notificable_type === 'Message') {
      this.notificable = new Message().fromJson(source.notificable);
    } else if (source.notificable_type === 'ExportOperation') {
      this.notificable = new ExportOperation().fromJson(source.notificable);
    } else if (source.notificable_type === 'User') {
      this.notificable = new User().fromJson(source.notificable);
    } else if (source.notificable_type === 'Contract') {
      this.notificable = new Contract().fromJson(source.notificable);
    } else if (source.notificable_type === 'Damage') {
      this.notificable = new Damage().fromJson(source.notificable);
    } else if (source.notificable_type === 'Import::Operation') {
      this.notificable = new ImportOperation().fromJson(source.notificable);
    } else if (source.notificable_type === 'PublicItem' && source.notificable) {
      this.notificable = new PublicItem().fromJson(source.notificable);
    } else if (source.notificable_type === 'PublicItem' && source.notificable_record) {
      this.notificable = new PublicItem().fromJson(source.notificable_record);
    }
    return this;
  }
}
