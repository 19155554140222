import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Notification } from '../models/notification.model';
import pick from 'lodash-es/pick';
import { ParamsService } from './params.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  readonly permitted_attributes = [
    'state'
  ];

  readonly slicedParams = [
    'state',
    'attribute',
    'direction',
    'with_stream'
  ];

  constructor(private http: HttpClient,
              private paramsService: ParamsService) { }


  index(filter: any): any {
    const params = new HttpParams();
    return this.http.get<Notification[]>('/notifications', { params: this.paramsService.handleFilter(filter, params, this.slicedParams),
                            observe: 'response' }).pipe();
  }

  delete(id: number) {
    return this.http.delete(`/notifications/${id}`);
  }

  get(id: number): any {
    return this.http.get<Notification>(`/notifications/${id}`);
  }

  save(record: Notification): any {
    const notification = new Notification().fromJson(pick(record, this.permitted_attributes));
    return this.http.put(`/notifications/${record.id}`, { notification });
  }

  create(userId: number): any {
    const notification = new Notification();
    notification.user_id = userId;
    return this.http.post(`/notifications`, { notification });
  }

  count(filter: any): any {
    const params = new HttpParams();
    return this.http.get(`/notifications/count`, { params: this.paramsService.handleFilter(filter, params, this.slicedParams) });
  }

}
